<template>
<el-select size="medium" class="w-100" placeholder="Bitte auswählen" v-model="value">
  <el-option value="DE" label="Deutschland"></el-option>
  <el-option value="AT" label="Österreich"></el-option>
  <el-option value="CH" label="Schweiz"></el-option>
  <el-option-group label="A">
    <el-option value="AF" label="Afghanistan"></el-option>
    <el-option value="EG" label="Ägypten"></el-option>
    <el-option value="AX" label="Åland"></el-option>
    <el-option value="AL" label="Albanien"></el-option>
    <el-option value="DZ" label="Algerien"></el-option>
    <el-option value="AS" label="Amerikanisch-Samoa"></el-option>
    <el-option value="VI" label="Amerikanische Jungferninseln"></el-option>
    <el-option value="AD" label="Andorra"></el-option>
    <el-option value="AO" label="Angola"></el-option>
    <el-option value="AI" label="Anguilla"></el-option>
    <el-option value="AQ" label="Antarktis"></el-option>
    <el-option value="AG" label="Antigua und Barbuda"></el-option>
    <el-option value="GQ" label="Äquatorialguinea"></el-option>
    <el-option value="AR" label="Argentinien"></el-option>
    <el-option value="AM" label="Armenien"></el-option>
    <el-option value="AW" label="Aruba"></el-option>
    <el-option value="AC" label="Ascension"></el-option>
    <el-option value="AZ" label="Aserbaidschan"></el-option>
    <el-option value="ET" label="Äthiopien"></el-option>
    <el-option value="AU" label="Australien"></el-option>
  </el-option-group>
  <el-option-group label="B">
    <el-option value="BS" label="Bahamas"></el-option>
    <el-option value="BH" label="Bahrain"></el-option>
    <el-option value="BD" label="Bangladesch"></el-option>
    <el-option value="BB" label="Barbados"></el-option>
    <el-option value="BY" label="Belarus (Weißrussland)"></el-option>
    <el-option value="BE" label="Belgien"></el-option>
    <el-option value="BZ" label="Belize"></el-option>
    <el-option value="BJ" label="Benin"></el-option>
    <el-option value="BM" label="Bermuda"></el-option>
    <el-option value="BT" label="Bhutan"></el-option>
    <el-option value="BO" label="Bolivien"></el-option>
    <el-option value="BA" label="Bosnien und Herzegowina"></el-option>
    <el-option value="BW" label="Botswana"></el-option>
    <el-option value="BV" label="Bouvetinsel"></el-option>
    <el-option value="BR" label="Brasilien"></el-option>
    <el-option value="VG" label="Britische Jungferninseln"></el-option>
    <el-option value="IO" label="Britisches Territorium im Indischen Ozean"></el-option>
    <el-option value="BN" label="Brunei Darussalam"></el-option>
    <el-option value="BG" label="Bulgarien"></el-option>
    <el-option value="BF" label="Burkina Faso"></el-option>
    <el-option value="BI" label="Burundi"></el-option>
  </el-option-group>
  <el-option-group label="C">
    <el-option value="EA" label="Ceuta, Melilla"></el-option>
    <el-option value="CL" label="Chile"></el-option>
    <el-option value="CN" label="Volksrepublik China"></el-option>
    <el-option value="CP" label="Clipperton"></el-option>
    <el-option value="CK" label="Cookinseln"></el-option>
    <el-option value="CR" label="Costa Rica"></el-option>
    <el-option value="CI" label="Côte d'Ivoire (Elfenbeinküste)"></el-option>
  </el-option-group>
  <el-option-group label="D">
    <el-option value="DK" label="Dänemark"></el-option>
    <el-option value="DG" label="Diego Garcia"></el-option>
    <el-option value="DM" label="Dominica"></el-option>
    <el-option value="DO" label="Dominikanische Republik"></el-option>
    <el-option value="DJ" label="Dschibuti"></el-option>
  </el-option-group>
  <el-option-group label="E">
    <el-option value="EC" label="Ecuador"></el-option>
    <el-option value="SV" label="El Salvador"></el-option>
    <el-option value="ER" label="Eritrea"></el-option>
    <el-option value="EE" label="Estland"></el-option>
  </el-option-group>
  <el-option-group label="F">
    <el-option value="FK" label="Falklandinseln"></el-option>
    <el-option value="FO" label="Färöer"></el-option>
    <el-option value="FJ" label="Fidschi"></el-option>
    <el-option value="FI" label="Finnland"></el-option>
    <el-option value="FR" label="Frankreich"></el-option>
    <el-option value="GF" label="Französisch-Guayana"></el-option>
    <el-option value="PF" label="Französisch-Polynesien"></el-option>
  </el-option-group>
  <el-option-group label="G">
    <el-option value="GA" label="Gabun"></el-option>
    <el-option value="GM" label="Gambia"></el-option>
    <el-option value="GE" label="Georgien"></el-option>
    <el-option value="GH" label="Ghana"></el-option>
    <el-option value="GI" label="Gibraltar"></el-option>
    <el-option value="GD" label="Grenada"></el-option>
    <el-option value="GR" label="Griechenland"></el-option>
    <el-option value="GL" label="Grönland"></el-option>
    <el-option value="GB" label="Großbritannien"></el-option>
    <el-option value="GP" label="Guadeloupe"></el-option>
    <el-option value="GU" label="Guam"></el-option>
    <el-option value="GT" label="Guatemala"></el-option>
    <el-option value="GG" label="Guernsey (Kanalinsel)"></el-option>
    <el-option value="GN" label="Guinea"></el-option>
    <el-option value="GW" label="Guinea-Bissau"></el-option>
    <el-option value="GY" label="Guyana"></el-option>
  </el-option-group>
  <el-option-group label="H">
    <el-option value="HT" label="Haiti"></el-option>
    <el-option value="HM" label="Heard- und McDonald-Inseln"></el-option>
    <el-option value="HN" label="Honduras"></el-option>
    <el-option value="HK" label="Hongkong"></el-option>
  </el-option-group>
  <el-option-group label="I">
    <el-option value="IN" label="Indien"></el-option>
    <el-option value="ID" label="Indonesien"></el-option>
    <el-option value="IM" label="Insel Man"></el-option>
    <el-option value="IQ" label="Irak"></el-option>
    <el-option value="IR" label="Iran"></el-option>
    <el-option value="IE" label="Irland"></el-option>
    <el-option value="IS" label="Island"></el-option>
    <el-option value="IL" label="Israel"></el-option>
    <el-option value="IT" label="Italien"></el-option>
  </el-option-group>
  <el-option-group label="J">
    <el-option value="JM" label="Jamaika"></el-option>
    <el-option value="JP" label="Japan"></el-option>
    <el-option value="YE" label="Jemen"></el-option>
    <el-option value="JE" label="Jersey (Kanalinsel)"></el-option>
    <el-option value="JO" label="Jordanien"></el-option>
  </el-option-group>
  <el-option-group label="K">
    <el-option value="KY" label="Kaimaninseln"></el-option>
    <el-option value="KH" label="Kambodscha"></el-option>
    <el-option value="CM" label="Kamerun"></el-option>
    <el-option value="CA" label="Kanada"></el-option>
    <el-option value="IC" label="Kanarische Inseln"></el-option>
    <el-option value="CV" label="Kap Verde"></el-option>
    <el-option value="KZ" label="Kasachstan"></el-option>
    <el-option value="QA" label="Katar"></el-option>
    <el-option value="KE" label="Kenia"></el-option>
    <el-option value="KG" label="Kirgisistan"></el-option>
    <el-option value="KI" label="Kiribati"></el-option>
    <el-option value="CC" label="Kokosinseln"></el-option>
    <el-option value="CO" label="Kolumbien"></el-option>
    <el-option value="KM" label="Komoren"></el-option>
    <el-option value="CD" label="Demokratische Republik Kongo"></el-option>
    <el-option value="KP" label="Demokratische Volksrepublik Korea (Nordkorea)"></el-option>
    <el-option value="KR" label="Republik Korea (Südkorea)"></el-option>
    <el-option value="HR" label="Kroatien"></el-option>
    <el-option value="CU" label="Kuba"></el-option>
    <el-option value="KW" label="Kuwait"></el-option>
  </el-option-group>
  <el-option-group label="L">
    <el-option value="LA" label="Laos"></el-option>
    <el-option value="LS" label="Lesotho"></el-option>
    <el-option value="LV" label="Lettland"></el-option>
    <el-option value="LB" label="Libanon"></el-option>
    <el-option value="LR" label="Liberia"></el-option>
    <el-option value="LY" label="Libyen"></el-option>
    <el-option value="LI" label="Liechtenstein"></el-option>
    <el-option value="LT" label="Litauen"></el-option>
    <el-option value="LU" label="Luxemburg"></el-option>
  </el-option-group>
  <el-option-group label="M">
    <el-option value="MO" label="Macao"></el-option>
    <el-option value="MG" label="Madagaskar"></el-option>
    <el-option value="MW" label="Malawi"></el-option>
    <el-option value="MY" label="Malaysia"></el-option>
    <el-option value="MV" label="Malediven"></el-option>
    <el-option value="ML" label="Mali"></el-option>
    <el-option value="MT" label="Malta"></el-option>
    <el-option value="MA" label="Marokko"></el-option>
    <el-option value="MH" label="Marshallinseln"></el-option>
    <el-option value="MQ" label="Martinique"></el-option>
    <el-option value="MR" label="Mauretanien"></el-option>
    <el-option value="MU" label="Mauritius"></el-option>
    <el-option value="YT" label="Mayotte"></el-option>
    <el-option value="MK" label="Mazedonien"></el-option>
    <el-option value="MX" label="Mexiko"></el-option>
    <el-option value="FM" label="Mikronesien"></el-option>
    <el-option value="MD" label="Moldawien (Republik Moldau)"></el-option>
    <el-option value="MC" label="Monaco"></el-option>
    <el-option value="MN" label="Mongolei"></el-option>
    <el-option value="ME" label="Montenegro"></el-option>
    <el-option value="MS" label="Montserrat"></el-option>
    <el-option value="MZ" label="Mosambik"></el-option>
    <el-option value="MM" label="Myanmar (Burma)"></el-option>
  </el-option-group>
  <el-option-group label="N">
    <el-option value="NA" label="Namibia"></el-option>
    <el-option value="NR" label="Nauru"></el-option>
    <el-option value="NP" label="Nepal"></el-option>
    <el-option value="NC" label="Neukaledonien"></el-option>
    <el-option value="NZ" label="Neuseeland"></el-option>
    <el-option value="NI" label="Nicaragua"></el-option>
    <el-option value="NL" label="Niederlande"></el-option>
    <el-option value="AN" label="Niederländische Antillen"></el-option>
    <el-option value="NE" label="Niger"></el-option>
    <el-option value="NG" label="Nigeria"></el-option>
    <el-option value="NU" label="Niue"></el-option>
    <el-option value="MP" label="Nördliche Marianen"></el-option>
    <el-option value="NF" label="Norfolkinsel"></el-option>
    <el-option value="NO" label="Norwegen"></el-option>
  </el-option-group>
  <el-option-group label="O">
    <el-option value="OM" label="Oman"></el-option>
    <el-option value="XO" label="Orbit"></el-option>
    <el-option value="TL" label="Osttimor (Timor-Leste)"></el-option>
  </el-option-group>
  <el-option-group label="P">
    <el-option value="PK" label="Pakistan"></el-option>
    <el-option value="PS" label="Palästinensische Autonomiegebiete"></el-option>
    <el-option value="PW" label="Palau"></el-option>
    <el-option value="PA" label="Panama"></el-option>
    <el-option value="PG" label="Papua-Neuguinea"></el-option>
    <el-option value="PY" label="Paraguay"></el-option>
    <el-option value="PE" label="Peru"></el-option>
    <el-option value="PH" label="Philippinen"></el-option>
    <el-option value="PN" label="Pitcairninseln"></el-option>
    <el-option value="PL" label="Polen"></el-option>
    <el-option value="PT" label="Portugal"></el-option>
    <el-option value="PR" label="Puerto Rico"></el-option>
  </el-option-group>
  <el-option-group label="Q"></el-option-group>
  <el-option-group label="R">
    <el-option value="TW" label="Republik China (Taiwan)"></el-option>
    <el-option value="CG" label="Republik Kongo"></el-option>
    <el-option value="RE" label="Réunion"></el-option>
    <el-option value="RW" label="Ruanda"></el-option>
    <el-option value="RO" label="Rumänien"></el-option>
    <el-option value="RU" label="Russische Föderation"></el-option>
  </el-option-group>
  <el-option-group label="S">
    <el-option value="BL" label="Saint-Barthélemy"></el-option>
    <el-option value="MF" label="Saint-Martin"></el-option>
    <el-option value="SB" label="Salomonen"></el-option>
    <el-option value="ZM" label="Sambia"></el-option>
    <el-option value="WS" label="Samoa"></el-option>
    <el-option value="SM" label="San Marino"></el-option>
    <el-option value="ST" label="São Tomé und Príncipe"></el-option>
    <el-option value="SA" label="Saudi-Arabien"></el-option>
    <el-option value="SE" label="Schweden"></el-option>
    <el-option value="SN" label="Senegal"></el-option>
    <el-option value="RS" label="Serbien"></el-option>
    <el-option value="SC" label="Seychellen"></el-option>
    <el-option value="SL" label="Sierra Leone"></el-option>
    <el-option value="ZW" label="Simbabwe"></el-option>
    <el-option value="SG" label="Singapur"></el-option>
    <el-option value="SK" label="Slowakei"></el-option>
    <el-option value="SI" label="Slowenien"></el-option>
    <el-option value="SO" label="Somalia"></el-option>
    <el-option value="ES" label="Spanien"></el-option>
    <el-option value="LK" label="Sri Lanka"></el-option>
    <el-option value="SH" label="St. Helena"></el-option>
    <el-option value="KN" label="St. Kitts und Nevis"></el-option>
    <el-option value="LC" label="St. Lucia"></el-option>
    <el-option value="PM" label="Saint-Pierre und Miquelon"></el-option>
    <el-option value="VC" label="St. Vincent und die Grenadinen"></el-option>
    <el-option value="ZA" label="Südafrika"></el-option>
    <el-option value="SD" label="Sudan"></el-option>
    <el-option value="GS" label="Südgeorgien und die Südlichen Sandwichinseln"></el-option>
    <el-option value="SR" label="Suriname"></el-option>
    <el-option value="SJ" label="Svalbard und Jan Mayen"></el-option>
    <el-option value="SZ" label="Swasiland"></el-option>
    <el-option value="SY" label="Syrien"></el-option>
  </el-option-group>
  <el-option-group label="T">
    <el-option value="TJ" label="Tadschikistan"></el-option>
    <el-option value="TZ" label="Tansania"></el-option>
    <el-option value="TH" label="Thailand"></el-option>
    <el-option value="TG" label="Togo"></el-option>
    <el-option value="TK" label="Tokelau"></el-option>
    <el-option value="TO" label="Tonga"></el-option>
    <el-option value="TT" label="Trinidad und Tobago"></el-option>
    <el-option value="TA" label="Tristan da Cunha"></el-option>
    <el-option value="TD" label="Tschad"></el-option>
    <el-option value="CZ" label="Tschechische Republik"></el-option>
    <el-option value="TN" label="Tunesien"></el-option>
    <el-option value="TR" label="Türkei"></el-option>
    <el-option value="TM" label="Turkmenistan"></el-option>
    <el-option value="TC" label="Turks- und Caicosinseln"></el-option>
    <el-option value="TV" label="Tuvalu"></el-option>
  </el-option-group>
  <el-option-group label="U">
    <el-option value="UG" label="Uganda"></el-option>
    <el-option value="UA" label="Ukraine"></el-option>
    <el-option value="HU" label="Ungarn"></el-option>
    <el-option value="UY" label="Uruguay"></el-option>
    <el-option value="UZ" label="Usbekistan"></el-option>
  </el-option-group>
  <el-option-group label="V">
    <el-option value="VU" label="Vanuatu"></el-option>
    <el-option value="VA" label="Vatikanstadt"></el-option>
    <el-option value="VE" label="Venezuela"></el-option>
    <el-option value="AE" label="Vereinigte Arabische Emirate"></el-option>
    <el-option value="US" label="Vereinigte Staaten von Amerika (USA)"></el-option>
    <el-option value="GB" label="Vereinigtes Königreich Großbritannien und Nordirland"></el-option>
    <el-option value="VN" label="Vietnam"></el-option>
  </el-option-group>
  <el-option-group label="W">
    <el-option value="WF" label="Wallis und Futuna"></el-option>
    <el-option value="CX" label="Weihnachtsinsel"></el-option>
    <el-option value="EH" label="Westsahara"></el-option>
  </el-option-group>
  <el-option-group label="Z">
    <el-option value="CF" label="Zentralafrikanische Republik"></el-option>
    <el-option value="CY" label="Zypern"></el-option>
  </el-option-group>
</el-select>
</template>

<script>
export default {
  name: "CountrySelect",
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
